/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { findBySlug, renderLineBreak } from "../helpers"
import RichText from "../components/rich-text"
import Container from "../components/container"
import Page from "../components/page"
import { paramsCheck } from "../functions/functions"
import Text from "../components/text"
import kebabCase from "lodash/kebabCase"
import cloneDeep from "lodash/cloneDeep"
import get from "lodash/get"


import { BLOCKS } from "@contentful/rich-text-types"


const ReadMoreModule =  ({
  basicCardData,
  parentCardData,
}) => {
  // console.log("basicCardData===>",basicCardData)
  const [showMoreContent, setShowMoreContent] = React.useState(0)
  const [showMoreOtherVenderContent, setShowMoreOtherVenderContent] = React.useState({})
  if (!basicCardData || !basicCardData.description)
    return null


    const setShowMoreOtherVender = e => {
      e = e.trim()
      if (e === "Design & Engineering") {
        setShowMoreOtherVenderContent({ content: designContent, heading: e })
      } else if (e === "Permitting") {
        setShowMoreOtherVenderContent({ content: permitingContent, heading: e })
      } else if (e === "Transportation & Installation") {
        setShowMoreOtherVenderContent({ content: transContent, heading: e })
      } else if (e === "General Contractor & Other Site Costs") {
        setShowMoreOtherVenderContent({ content: generalContent, heading: e })
      } else if (e === "Landscaping") {
        setShowMoreOtherVenderContent({ content: lanscapeContent, heading: e })
      }
    }

  const readMoreIdx = basicCardData.description.json.content.findIndex(
    (node, idx) => {
      const nodeContent = get(node, "content[0].value", "")
      // console.log(nodeContent, "this is read more")
      return nodeContent.trim() === "<READ MORE>"
    }
  )
 

  let initialContent = cloneDeep(basicCardData.description)
  let readMoreContent = cloneDeep(basicCardData.description)
 

  

  if (readMoreIdx > 0) {
    if (basicCardData.title === "Pricing for LivingHomes (Standard Designs)") {
      readMoreContent.json.content = readMoreContent.json.content.slice(
        readMoreIdx + 1,
        readMoreContent.json.content.length - 2
      )

      initialContent.json.content = initialContent.json.content.slice(
        0,
        readMoreIdx
      )
    } else {
      initialContent.json.content = initialContent.json.content.slice(
        0,
        readMoreIdx
      )
      readMoreContent.json.content = readMoreContent.json.content.slice(
        readMoreIdx + 1,
        readMoreContent.json.length
      )
    }
  }

  let contentOtherVender = []
  let designContent = cloneDeep(readMoreContent)
  let permitingContent = cloneDeep(readMoreContent)
  let transContent = cloneDeep(readMoreContent)
  let generalContent = cloneDeep(readMoreContent)
  let lanscapeContent = cloneDeep(readMoreContent)



    if (
      basicCardData.title === "Pricing for Services Provided by Other Vendors"
    ) {
      contentOtherVender = readMoreContent.json.content.filter(
        head => head.nodeType === "heading-4"
      )

      const designIndex = readMoreContent.json.content.findIndex(
        (node, idx) => {
          const nodeDesignContent = get(node, "content[0].value", "")
          return nodeDesignContent.trim() === "Design & Engineering"
        }
      )
      const permitIndex = readMoreContent.json.content.findIndex(
        (node, idx) => {
          const nodePermitContent = get(node, "content[0].value", "")
          return nodePermitContent.trim() === "Permitting"
        }
      )
      const transIndex = readMoreContent.json.content.findIndex((node, idx) => {
        const nodeTransContent = get(node, "content[0].value", "")
        return nodeTransContent.trim() === "Transportation & Installation"
      })
      const generalIndex = readMoreContent.json.content.findIndex(
        (node, idx) => {
          const nodeGeneralContent = get(node, "content[0].value", "")
          return (
            nodeGeneralContent.trim() ==="General Contractor & Other Site Costs"
          )
        }
      )
      const landIndex = readMoreContent.json.content.findIndex((node, idx) => {
        const nodeLandContent = get(node, "content[0].value", "")
        return nodeLandContent.trim() === "Landscaping"
      })
      if (
        designIndex == 0 &&
        permitIndex > 0 &&
        transIndex > 0 &&
        generalIndex > 0 &&
        landIndex > 0
      ) {
        lanscapeContent.json.content = readMoreContent.json.content.slice(
          landIndex,
          readMoreContent.json.content.length
        )
        generalContent.json.content = readMoreContent.json.content.slice(
          generalIndex,
          landIndex
        )
        transContent.json.content = readMoreContent.json.content.slice(
          transIndex,
          generalIndex
        )
        permitingContent.json.content = readMoreContent.json.content.slice(
          permitIndex,
          transIndex
        )
        designContent.json.content = readMoreContent.json.content.slice(
          designIndex,
          permitIndex
        )
      }
      //  console.log("teeeeesstt",readMoreContent.json.content)
    } 
  


  let initialExtraContent = cloneDeep()

  if (basicCardData.title === "Pricing for LivingHomes (Standard Designs)") {
    const extraContentIndex = basicCardData.description.json.content.findIndex(
      (node, idx) => {
        const nodeExtraContent = get(node, "content[0].value", "")
        return (
          nodeExtraContent.trim() === "How do I get a specific cost estimate?"
        )
      }
    )

    initialExtraContent = cloneDeep(basicCardData.description)

    if (extraContentIndex > 0) {
      initialExtraContent.json.content = basicCardData.description.json.content.slice(
        extraContentIndex,
        basicCardData.description.json.content.length
      )
    }
  }


  const ScrollTo = name => {
    if(typeof document){
      const elem = document.getElementById(name)
      window.scrollTo({ left: 0, top: elem.offsetTop + -20, behavior: "smooth" })
    }
  }
  // console.log("initialContent==>",initialContent)
  // console.log("initialExtraContent==>",initialExtraContent)
  // console.log("readMoreContent===>",readMoreIdx,readMoreContent)

  return (
    <div>
      {initialContent &&
        initialContent.json &&
        documentToReactComponents(initialContent.json, {
          renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => (
              <Text
                customStyle={{
                  mb: theme => theme.spacing.horizontal,
                  color: theme => theme.colors.primary,
                  fontSize: "28px",
                }}
                type="h3"
              >
                {children}
              </Text>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
              <Text
                customStyle={{
                  mb: 0,
                }}
                type="h4"
              >
                {children}
              </Text>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => {
              if (children[0].includes("Custom Project Pricing,” below).")) {
                const child = children[0].split("Custom Project Pricing")
                return (
                  <Text
                    customStyle={{
                      mb: theme => theme.spacing.horizontal,
                    }}
                  >
                    {child[0]}
                    <span
                      sx={{
                        // ":hover*":{
                        //     color:theme=>theme.colors.primary
                        //   },
                        cursor: "pointer",
                        color: theme => theme.colors.primary,
                      }}
                      onClick={() => ScrollTo("pricing-for-custom-projects")}
                    >
                      Custom Project Pricing
                    </span>
                    {child[1]}
                  </Text>
                )
              } else {
                return (
                  <Text
                    customStyle={{
                      mb: theme => theme.spacing.horizontal,
                    }}
                  >
                    {children}
                  </Text>
                )
              }
            },
            [BLOCKS.UL_LIST]: (node, children) => (
              <ul
                sx={{
                  mb: 0,
                  li: {
                    mb: "0px",
                    //  "p+&": {
                    //     // mt:0,
                    //   },
                  },
                }}
              >
                {children}
              </ul>
            ),
            [BLOCKS.LIST_ITEM]: (node, children) => (
              <li
                sx={{
                  mb: 0,
                  ":last-child": {
                    mb: theme => theme.spacing.horizontal,
                  },
                }}
              >
                {children}
              </li>
            ),
          },
        })}
      {readMoreIdx > 0 && !showMoreContent && basicCardData.title !== "Pricing for Services Provided by Other Vendors"? (
        <div
          onClick={() => setShowMoreContent(1)}
          onKeyDown={() => setShowMoreContent(1)}
          role="button"
          tabIndex="0"
          sx={{ cursor: "pointer" }}
        >
          <Text
            customStyle={{
              color: theme => theme.colors.primary,
              my: theme => theme.space[3],
            }}
          >
            Read more
            <span
              sx={{
                fontSize: 14,
                ml: 2,
                display: "inline-block",
                transform: "scaleX(1.8) translateY(-5px)",
              }}
            >
              {"\u2304"}
            </span>
          </Text>
        </div>
      ) : null}
      {basicCardData.title === "Pricing for Services Provided by Other Vendors"
        ? contentOtherVender.map(cardData => {
            return <>
            <div
            sx={{
              border: 0,
              background: "transparent",
              cursor: "pointer",
              display: "flex",
              p: 0,
              ":hover *": {
                color: theme => theme.colors.primary,
              },
            }}
            onClick={() => setShowMoreOtherVender(cardData.content?cardData.content[0].value:"")}
            key={cardData}
          >
            <img
              sx={{ height: "18px", m: "3px 5px 3px 0",filter: "saturate(1.5)" }}
              src={parentCardData.mobileImage.file.url}
              alt={parentCardData.mobileImage.title}
            />
            <Text
              customStyle={{
                mb: "3px",
                fontWeight: "bold",
                cursor: "pointer" 
              }}
            >
              <span>
              {cardData.content && cardData.content[0].value}
              </span>
            </Text>
          </div>
          {showMoreOtherVenderContent && showMoreOtherVenderContent.heading === ((cardData.content[0].value).trim())?<div sx={{ mt: 10, ml: 25 }}>
          {documentToReactComponents(showMoreOtherVenderContent.content.json, {
            renderNode: {
              [BLOCKS.HEADING_4]: (node, children) => (
                <Text
                  customStyle={{
                    mb: 0,
                  }}
                  type="h4"
                >
                  {/* {children} */}
                </Text>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Text
                  customStyle={{
                    mb: theme => theme.spacing.horizontal,
                    mt: 10
                  }}
                >
                  {/* {console.log("children-------------",children)} */}
                  {children}
                </Text>
              ),
            },
          })}
          <div
            onClick={() => setShowMoreOtherVenderContent({})}
            onKeyDown={() => setShowMoreOtherVenderContent({})}
            role="button"
            tabIndex="0"
            sx={{ cursor: "pointer" }}
          >
            <Text
              customStyle={{
                color: theme => theme.colors.primary,
                my: theme => theme.space[3],
              }}
            >
              Read less
              <span
                sx={{
                  fontSize: 14,
                  ml: 2,
                  display: "inline-block",
                  transform: "scaleX(1.8) translateY(7px) rotate(180deg)",
                }}
              >
                {"\u2304"}
              </span>
            </Text>
          </div>
        </div>:null}
              
          </>
          })
        : null}
      {readMoreIdx && showMoreContent ? (
        <div>
          {documentToReactComponents(readMoreContent.json, {
            renderNode: {
              [BLOCKS.HEADING_3]: (node, children) => (
                <Text
                  customStyle={{
                    mb: theme => theme.spacing.horizontal,
                    color: theme => theme.colors.primary,
                  }}
                  type="h3"
                >
                  {children}
                </Text>
              ),
              [BLOCKS.HEADING_4]: (node, children) => (
                <Text
                  customStyle={{
                    mb: 0,
                  }}
                  type="h4"
                >
                  {children}
                </Text>
              ),
              [BLOCKS.HEADING_5]: (node, children) => (
                // children[0].props &&
                // children[0].props.children == "Fabrication" ? (
                //   <Text
                //     customStyle={{
                //       mb: 0,
                //       color: theme => theme.colors.primary,
                //       fontSize: "16px",
                //     }}
                //     // type="h1"
                //   >
                //     {children}
                //   </Text>
                // ) : (
                <Text
                  customStyle={{
                    mb: 0,
                    color: theme => theme.colors.primary,
                    fontSize: "15px",
                  }}
                  type="h5"
                >
                  {children}
                </Text>
              ),
              [BLOCKS.UL_LIST]: (node, children) => (
                <ul
                  sx={{
                    mb: 0,
                    p: {
                      mb: 0,
                    },
                    mt: 0,
                    // "li":{
                    //   mb:"0px",
                    //    "p+&": {
                    //       // mt:0,
                    //     },
                    // }
                  }}
                >
                  {children}
                </ul>
              ),
              [BLOCKS.LIST_ITEM]: (node, children) => (
                <li
                  sx={{
                    mb: 0,
                    p: {
                      mb: 0,
                    },
                  }}
                >
                  {children}
                </li>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Text
                  customStyle={{
                    mb: theme => theme.spacing.horizontal,
                  }}
                >
                  {/* {console.log("children",children)} */}
                  {children}
                </Text>
              ),
            },
          })}
          <div
            onClick={() => setShowMoreContent(0)}
            onKeyDown={() => setShowMoreContent(0)}
            role="button"
            tabIndex="0"
            sx={{ cursor: "pointer" }}
          >
            <Text
              customStyle={{
                color: theme => theme.colors.primary,
                my: theme => theme.space[3],
              }}
            >
              Read less
              <span
                sx={{
                  fontSize: 14,
                  ml: 2,
                  display: "inline-block",
                  transform: "scaleX(1.8) translateY(7px) rotate(180deg)",
                }}
              >
                {"\u2304"}
              </span>
            </Text>
          </div>
        </div>
      ) : null}

      {initialExtraContent && initialExtraContent.json
        ? documentToReactComponents(initialExtraContent.json, {
            renderNode: {
              [BLOCKS.HEADING_3]: (node, children) => (
                <Text
                  customStyle={{
                    mb: theme => theme.spacing.horizontal,
                    color: theme => theme.colors.primary,
                  }}
                  type="h3"
                >
                  {children}
                </Text>
              ),
              [BLOCKS.HEADING_4]: (node, children) => (
                <Text
                  customStyle={{
                    mb: 0,
                  }}
                  type="h4"
                >
                  {children}
                </Text>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                  <Text
                    customStyle={{
                      mb: theme => theme.spacing.horizontal,
                    }}
                  >
                    {children}
                  </Text>
                )
              },
              [BLOCKS.UL_LIST]: (node, children) => (
                <ul
                  sx={{
                    mb: 0,
                    li: {
                      mb: "0px",
                      //  "p+&": {
                      //     // mt:0,
                      //   },
                    },
                  }}
                >
                  {children}
                </ul>
              ),
              [BLOCKS.LIST_ITEM]: (node, children) => (
                <li
                  sx={{
                    mb: 0,
                    ":last-child": {
                      mb: theme => theme.spacing.horizontal,
                    },
                  }}
                >
                  {children}
                </li>
              ),
            },
          })
        : null}
    </div>
  )
}

const PricingDetailPage = ({ data }) => {
  
  paramsCheck()

  const renderTableModule = () => {

    const tableHeading = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pricing-details-table-headings",
    })
    const questionRow1 = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "question-row-1",
    })
    const questionRow2 = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "question-row-2",
    })
    const questionRow3 = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "question-row-3",
    })
    const questionRow4 = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "question-row-4",
    })

    const ScrollTo = name => {
      if(typeof document){
        const elem = document.getElementById(name)
        window.scrollTo({ left: 0, top: elem.offsetTop + -20, behavior: "smooth" })
      }
    }

    if (!data.contentfulPage.bladePatternList) return null

    // let JoinData = {
    //   description: {
    //     nodeType: "document",
    //     json: {
    //       data: {},
    //       content: [
    //         {
    //           data: {},
    //           content: [
    //             {
    //               data: {},
    //               marks: [],
    //               value:
    //                 "Sign up for our monthly newsletter to receive new posts, news, and more.\n",
    //               nodeType: "text",
    //             },
    //           ],
    //           nodeType: "paragraph",
    //         },
    //       ],
    //       nodeType: "document",
    //     },
    //     description:
    //       '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Sign up for our monthly newsletter to receive new posts, news, and more.\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
    //   },
    // }

// json:
// content: Array(1)
// 0:
// content: Array(1)
// 0:
// data: {}
// marks: [{…}]
// nodeType: "text"
// value: "What’s included?"
// __proto__: Object
// length: 1
// __proto__: Array(0)
// data: {}
// nodeType: "paragraph"
// __proto__: Object
// length: 1
// __proto__: Array(0)
// data: {}
// nodeType: "document"


    const arr = [
                  {text:"What will it cost?" , refer: ""},
                  {text:"Jump to section" , refer: "pricing-for-services-provided-by-others-vendors"},
                  {text:"Jump to section" , refer: "pricing-for-custom-projects"},
                  {text:"Jump to section" , refer: "pricing-for-services-provided-by-others-vendors"},
                  {text:"Jump to section" , refer: "pricing-for-services-provided-by-others-vendors"},
                ];

    return (
      <div>
        <table
          className="custom-table"
          sx={{
            fontfamily: "Helvetica Neue,sans-serif",
          }}
        >
          {tableHeading &&
            tableHeading.contentCards &&
            tableHeading.contentCards.map((heading) =>
              heading.description ? (
                <th
                  sx={{
                    border: "1px solid black",
                    backgroundColor: "#445466",
                    color: "white",
                  }}
                >
                  {/* {documentToReactComponents(heading.description.json)} */}
                  <RichText
                    data={heading.description}
                    sx={{ color: "white" }}
                  />
                </th>
              ) : (
                <th
                  sx={{
                    border: "1px solid black",
                    // backgroundColor: "#445466",
                    color: "white",
                  }}
                ></th>
              )
            )}
          {/* {
           questionsAns && questionsAns.length>0
           && questionsAns.map(ele =><tr>
            { ele.contentCards &&
            ele.contentCards.length>0 && ele.contentCards.map(
            obj => obj.description && <td>{documentToReactComponents(obj.description.json)}</td>
            ) }
            </tr>)
         }  */}
          <tbody>
            {questionRow2 && questionRow2.contentCards.length > 0 && (
              <tr>
                {questionRow2.contentCards.map(
                  (ele, index) =>
                    ele.description && (
                      <td
                        sx={
                          index !== 0
                            ? {
                                border: "1px solid black",
                                verticalAlign: "top",
                              }
                            : {
                                border: "1px solid black",
                                paddingLeft: "10px !important",
                                verticalAlign: "top",
                              }
                        }
                      >
                        <RichText data={ele.description} />
                      </td>
                    )
                )}
              </tr>
            )}
            {questionRow1 && questionRow1.contentCards.length > 0 && (
              <tr>
                {questionRow1.contentCards.map(
                  (ele, index) =>
                    ele.description && (
                      <td
                        sx={
                          index !== 0
                            ? {
                                border: "1px solid black",
                                verticalAlign: "top",
                              }
                            : {
                                border: "1px solid black",
                                paddingLeft: "10px !important",
                                verticalAlign: "top",
                              }
                        }
                      >
                        {/* {documentToReactComponents(ele.description.json)} */}
                        <RichText data={ele.description} />
                      </td>
                    )
                )}
              </tr>
            )}
            {questionRow3 && questionRow3.contentCards.length > 0 && (
              <tr>
                {questionRow3.contentCards.map(
                  (ele, index) =>
                    ele.description && (
                      <td
                        sx={
                          index !== 0
                            ? {
                                border: "1px solid black",
                                verticalAlign: "top",
                              }
                            : {
                                border: "1px solid black",
                                paddingLeft: "10px !important",
                                verticalAlign: "top",
                              }
                        }
                      >
                        {documentToReactComponents(ele.description.json,{
                            renderNode: {
                            [BLOCKS.UL_LIST]: (node, children) => (
                              <ul
                                sx={{
                                    mb: 0,
                                    // "p+&": {
                                    //   mt: -20,
                                    // },
                                    "li":{
                                      mb:0
                                    }
                                }}
                              >
                                {children}
                              </ul>
                            ),
                            [BLOCKS.LIST_ITEM]: (node, children) =>(
                              <li 
                                sx = {{
                                  mb: 0
                                }}
                              >
                                {children}
                              </li>
                            ),
                            [BLOCKS.PARAGRAPH]: (node, children) =>
                                  (<Text 
                                    customStyle = {{
                                        mb: theme => theme.spacing.horizontal,
                                    }}
                                  >
                                    {children}
                                  </Text>)
                          }
                        })}
                        {/* <RichText data={ele.description} /> */}
                      </td>
                    )
                )}
              </tr>
            )}
            {/* {questionRow4 && questionRow4.contentCards.length > 0 && (
              <tr>
                {questionRow4.contentCards.map((ele, index) =>
                  ele.description && index !== 1 ? (
                    <td
                      sx={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        paddingLeft: "10px !important",
                      }}
                    >
                      <RichText data={ele.description} />
                    </td>
                  ) : (
                    <td
                      sx={{ border: "1px solid black", verticalAlign: "top" }}
                      colspan="4"
                    >
                      <RichText data={ele.description} />
                    </td>
                  )
                )}
              </tr>
            )} */}
            <tr>
              {arr.map((obj,index)=>
                            <td 
                              sx = {
                                index !== 0
                                ? {
                                    border: "1px solid black",
                                    verticalAlign: "top",
                                  }
                                : {
                                    border: "1px solid black",
                                    paddingLeft: "10px !important",
                                    verticalAlign: "top",
                                  }
                              }
                            >
                              {index === 0 ?
                              <p  
                                sx = {
                                  {
                                    "color": "#000",
                                    "font-family": "Helvetica Neue,sans-serif",
                                    "font-size": "15px",
                                    "font-weight": 400,
                                    "line-height": "20px"
                                  }
                                }
                                
                                // onClick={() => {obj.refer && ScrollTo(kebabCase(obj.refer))}}

                              >
                                  {/* {index ===0 ?<b>{obj.text}</b>:obj.text} */}
                                <b>{obj.text}</b>
                              </p>
                              :
                              <p 
                                sx = {{
                                    // "color": "#000",
                                    "font-family": "Helvetica Neue,sans-serif",
                                    "font-size": "15px",
                                    "font-weight": 400,
                                    "line-height": "20px",
                                     color : theme => theme.colors.primary,
                                     cursor : "pointer" 

                                  }
                                }
                                
                                onClick={() => {obj.refer && ScrollTo(kebabCase(obj.refer))}}
                              >
                                {obj.text}
                              </p>
                              }
                            </td>)}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  const renderContentModule = () => {
    const sectionlistData = data.contentfulSection.richText

    return (
      <Container>
        {sectionlistData && documentToReactComponents(sectionlistData.json)}
        {/* <RichText data={sectionlistData}/> */}
      </Container>
    )
  }
  const renderContentBeforeTable = () => {
    const sectionData = data.contentfulSection.contentCards.find(
      list => list.slug === "pricing-detail-before-table"
    )

    return (
      <Container
        customStyle={{ fontfamily: "Helvetica Neue,sans-serif !important" }}
      >
        <div className="font-helvetica">
          {sectionData &&<>
          {/* {documentToReactComponents(sectionData.description.json)} */}
          {/* <RichText data={sectionData.description}/> */}
          {documentToReactComponents(sectionData.description.json, {
            renderNode: {
              // [BLOCKS.HEADING_1]: (node, children) => (
              //   <h1>{children}</h1>
              // ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 sx={{mb:0}} type="h3">{children}</h3>
              ),
              [BLOCKS.HEADING_4]: (node, children) => (
                <Text customStyle={{mb:theme=>theme.spacing.horizontal/2}} type="h4">{children}</Text>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => {
                  if(
                    node.content[0].value.trim() === "Pricing for Custom Projects (Custom Designs)" 
                    || node.content[0].value.trim() === "Services and Vendors"
                    || node.content[0].value.trim() === "Pricing for LivingHomes (Standard Designs)"
                  ){
                    return( 
                      <Text 
                        customStyle = {{
                            mb: 0,
                            // "p+&": {
                            //   mt: 2,
                            // },
                        }}
                      >
                        {children}
                      </Text>)
                  }else{
                    return (
                    <Text  
                      customStyle={{
                        mb: theme => theme.spacing.horizontal,
                      }}
                    >
                      {children}
                    </Text>)
                  }
               
              }
              ,
              [BLOCKS.OL_LIST]: () => null,
              [BLOCKS.UL_LIST]: () => null,
            },
            renderText: text => renderLineBreak(text),
          })}
          </>
          }
        </div>
      </Container>
    )
  }
  const renderContentAfterTable = () => {
    const sectionData = data.contentfulSection.contentCards.find(
      list => list.slug === "pricing-detail-after-table"
    )
    return (
      <Container>
        <div className="font-helvetica">
          {sectionData &&
            documentToReactComponents(sectionData.description.json)
          // <RichText data={sectionData.description}/>
          }
        </div>
      </Container>
    )
  }

  // const ScrollTo = name => {
  //   if(typeof document){
  //     const elem = document.getElementById(name)
  //     window.scrollTo({ left: 0, top: elem.offsetTop + -20, behavior: "smooth" })
  //   }
  // }

  const renderContent = () =>{
    const content = findBySlug({
      list : data.contentfulPage.bladePatternList,
      slug : 'contents-headings'
    })

    if(!content) return null

    const contentCards =  content.contentCards.filter(ele => ele.title);
    contentCards.map((row,i) =>{
      let content = row.description.json.content[0].content[0]
      if(content && content.value == "Pricing Transparency")
      {
        contentCards.splice(i,1)
      }
    })
    // const ScrollTo = name => {
    //   const elem = document.getElementById(name)
    //   window.scrollTo({ left: 0, top: elem.offsetTop + -20, behavior: "smooth" })
    // }

    const ScrollTo = name => {
    if(typeof document){
      const elem = document.getElementById(name)
      window.scrollTo({ left: 0, top: elem.offsetTop + -20, behavior: "smooth" })
    }
  }

    return (
      <Container>
        <RichText data={content.contentCards[0].description} />
        {/* {documentToReactComponents(content.description.description)} */}
        <Text
          type="h5"
          customStyle={{ mb: theme => theme.spacing.horizontal / 2 }}
        >
          {content.title}
        </Text>
        <div sx={{ mb: theme => theme.spacing.horizontal }}>
          {contentCards.map(cardData => (
            <div
              sx={{
                border: 0,
                background: "transparent",
                cursor: "pointer",
                display: "flex",
                p: 0,
                ":hover *": {
                  color: theme => theme.colors.primary,
                },
                // ":last-child": { mb: theme=>theme.spacing.horizontal },
              }}
              onClick={() => cardData.slug ? ScrollTo(kebabCase(cardData.slug)) : null}
              key={kebabCase(cardData.title)}
            >
              <img
                sx={{ height: "18px", m: "3px 5px 3px 0",filter: "saturate(1.5)" }}
                src={content.mobileImage.file.url}
                alt={content.mobileImage.title}
              />
              <Text
                customStyle={{
                  mb: "3px",
                }}
              >
                {cardData.title && cardData.title}
              </Text>
            </div>
          ))}
        </div>
        {contentCards &&
          contentCards.length > 0 &&
          contentCards.map(cardData => {
            if (cardData.title === "Services and Vendors") {
              return (
                <div id={kebabCase(cardData.slug)}>
                  {documentToReactComponents(cardData.description.json, {
                    renderNode: {
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <Text
                          customStyle={{
                            // mb: theme => theme.spacing.horizontal,
                            color: theme => theme.colors.primary,
                            fontSize: "28px"
                          }}
                          type="h3"
                        >
                          {children}
                        </Text>
                      ),
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Text
                          customStyle={{
                            mb: theme => theme.spacing.horizontal,
                          }}
                        >
                          {children}
                        </Text>
                      ),
                      // [BLOCKS.UL_LIST]: (node, children) => (
                      //   <ul
                      //     sx={{
                      //         mb: 0,
                      //         // "p+&": {
                      //         //   mt: -20,
                      //         // },
                      //         "li":{
                      //           mb:0
                      //         }
                      //     }}
                      //   >
                      //     {children}
                      //   </ul>
                      // ),
                      // [BLOCKS.LIST_ITEM]: (node, children) =>(
                      //   <li
                      //     sx = {{
                      //       mb: 0
                      //     }}
                      //   >
                      //     {children}
                      //   </li>
                      // ),
                    },
                    renderText: text => renderLineBreak(text),
                  })}
                  {/* <RichText data = {cardData.description} /> */}
                  {renderTableModule()}
                </div>
              )
            } else {
              return (
                <div id={kebabCase(cardData.slug)}>
                  <ReadMoreModule
                    basicCardData={cardData}
                    parentCardData={content}
                    // titleStyle={{ fontSize: [30, 30, 40] }}
                    columnSizes={{ left: [12, 12, 8], right: [12, 12, 4] }}
                  />
                  {/* <RichText data = {cardData.description} /> */}
                </div>
              )
            }
          })}
      </Container>
    )
  }

  return (
    <React.Fragment>
      <Page data={data.contentfulPage}> 
        <Container
          customStyle={{
            py: 4,
            fontfamily: "Helvetica Neue,sans-serif !important",
          }}
        >
          {renderContent()}
          {/* {renderContentBeforeTable()} */}
           {/* {renderTableModule()}
          {renderContentAfterTable()} */}
          {/* {renderContentModule()} */}
        </Container>
      </Page>
    </React.Fragment>
  )
}

export default PricingDetailPage

export const query = graphql`
  query pricingDetailPageQuery {
    contentfulPage(slug: { eq: "pricing-detail" }) {
      ...PageData
      ...BladePatternData
    }

    contentfulSection(slug: { eq: "page-section-pricing-detail" }) {
      id
      slug
      richText {
        id
        json
      }
      contentCards {
        id
        title
        slug
        description {
          id
          json
        }
      }
    }
  }
`